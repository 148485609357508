<template>
  <v-container class="pa-0" fluid style="height: 100vh">
    <v-row class="h-100" no-gutters>
      <v-col
        class="hidden-sm-and-down"
        md="7"
        style="background-color: #0d01c7"
      >
        <img
          src="@/assets/img/bg.jpg"
          width="100%"
          height="100%"
          alt="bg-image"
          style="width: 100%; height: 100%"
        />
      </v-col>
      <v-col cols="12" sm="8" md="5" class="mx-auto relative">
        <notification-message
          :message.sync="message"
          :type="error && message ? 'error' : 'success'"
        />
        <v-container class="d-flex align-center justify-center h-100">
          <v-card
            width="340"
            flat
            class="text-center animate__animated animate__zoomIn"
          >
            <v-col>
              <a
                class="black--text text-subtitle-1 d-flex justify-center align-center mb-4"
                href="https://snappyexchange.com"
              >
                <img :src="logoSnappy" width="45" height="45" />
                <span class="font-weight-bold">SnappyExchange</span>
              </a>
              <h3>Reset your password</h3>
              <h5 class="font-weight-regular">Enter your new password below</h5>
            </v-col>
            <v-col>
              <v-form ref="resetPasswordForm" @submit.prevent="validate">
                <v-container>
                  <v-text-field
                    :disabled="loading"
                    name="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    outlined
                    rounded
                    label="New Password"
                    v-model="form.password"
                    :rules="[rules.required, rules.minimum]"
                  ></v-text-field>
                  <v-text-field
                    :disabled="loading"
                    name="confirm-password"
                    :append-icon="
                      showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :type="showConfirmPassword ? 'text' : 'password'"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                    outlined
                    rounded
                    label="Confirm New Password"
                    v-model="form.confirmPassword"
                    :rules="[rules.required, rules.minimum, passwordIsEqual]"
                  ></v-text-field>
                  <v-btn
                    class="mt-5"
                    block
                    rounded
                    :loading="loading"
                    color="primary"
                    elevation="2"
                    type="submit"
                    >Reset password</v-btn
                  >
                </v-container>
              </v-form>
            </v-col>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logoSnappy from '../../assets/logo.png'
import notificationMessage from '../../components/notification/notificationMessage.vue'
import api from '../../api/users/auth'
import rules from '../../utils/rules'

export default {
  components: { notificationMessage },
  data() {
    return {
      token: '',
      showPassword: false,
      showConfirmPassword: false,
      error: false,
      message: null,
      loading: false,
      logoSnappy,
      form: {
        password: '',
        confirmPassword: ''
      },
      rules: {
        required: rules.required,
        minimum: rules.valueWithAtLeast8Characters
      }
    }
  },
  computed: {
    passwordIsEqual() {
      if (this.form.password) {
        return (
          this.form.password === this.form.confirmPassword ||
          "Password doesn't match"
        )
      }
      return "Password doesn't match"
    }
  },
  methods: {
    validate() {
      if (this.$refs.resetPasswordForm.validate()) this.resetPassword()
    },
    async resetPassword() {
      this.loading = true
      const res = await api
        .auth()
        .resetPassword({ password: this.form.password, token: this.token })

      // console.log(res)

      if (res.error) {
        this.error = true
        this.message = res.errorMessage.message
        this.loading = false
        return
      }

      this.message = `${res.data.data} You will be redirected to login`
      setTimeout(() => {
        this.$router.push('/login')
      }, 3000)
      this.error = false
      this.loading = false
    }
  },
  created() {
    this.token = this.$route.params.token
  }
}
</script>

<style></style>
